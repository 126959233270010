import { template as template_f69b1bd02be44739b477b3da5473e755 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f69b1bd02be44739b477b3da5473e755(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
