import { template as template_f539bdeb58874bc5806aa9b8cca47f48 } from "@ember/template-compiler";
const FKLabel = template_f539bdeb58874bc5806aa9b8cca47f48(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
