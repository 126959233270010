import { template as template_1a9ac4bd7e93442eaf43d8bd94918afb } from "@ember/template-compiler";
const WelcomeHeader = template_1a9ac4bd7e93442eaf43d8bd94918afb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
