import { template as template_93f467f451314279a9011b6c787f8756 } from "@ember/template-compiler";
const FKText = template_93f467f451314279a9011b6c787f8756(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
